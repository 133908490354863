<script>
import { Line } from 'vue-chartjs'
import axios from '@/http'

function convertHex (hex, opacity) {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  const result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')'
  return result
}

export default {
  name: 'GraficoLinhasDia',
  extends: Line,
  data () {
    return {
      lista: []
    }
  },
  methods: {
    async getData() {
      let URL = '/dashboard/graficodia'
      await axios.get(`${URL}`)
          .then(response => {
              this.lista = response.data.data
              this.renderGraph();
          })
          .catch(response => {
          })
    },
    renderGraph(){
      let labels = this.lista.map(function(e) { return e.dia; } );
      let data01 = this.lista.map(function(e) { return e.areceber; } );
      let data02 = this.lista.map(function(e) { return e.apagar; } );
      let data03 = this.lista.map(function(e) { return e.recebidas; } );
      let data04 = this.lista.map(function(e) { return e.pagas; } );
      
      this.renderChart({
        labels: labels,
        datasets: [
          {
            label: 'A Receber no dia',
            backgroundColor: convertHex('#4dbd74', 10),
            borderColor: '#4dbd74',
            pointHoverBackgroundColor: '#fff',
            borderWidth: 2,
            data: data01
          },
          {
            label: 'A Pagar no Dia',
            backgroundColor: convertHex('#f86c6b', 10),
            borderColor: '#f86c6b',
            pointHoverBackgroundColor: '#fff',
            borderWidth: 2,
            data: data02
          },
          {
            label: 'Recebidas no Dia',
            backgroundColor: 'transparent',
            borderColor: '#4dbd74',
            pointHoverBackgroundColor: '#fff',
            borderWidth: 1,
            borderDash: [8, 5],
            data: data03
          },
          {
            label: 'Pagas no Dia',
            backgroundColor: 'transparent',
            borderColor: '#f86c6b',
            pointHoverBackgroundColor: '#fff',
            borderWidth: 1,
            borderDash: [8, 5],
            data: data04
          }
        ]
      }, {
        maintainAspectRatio: false,
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              /*maxTicksLimit: 5,
              stepSize: Math.ceil(250 / 5),
              max: 250*/
            },
            gridLines: {
              display: true
            }
          }]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      })
    }
  },
  sockets: {
    async mctatufinanceiro (data) {
      await this.getData()
    }
  },
  async mounted () {
    await this.getData();
  }
}
</script>

<style scoped>
  #line-chart{
    /*max-width: 300px;*/
    max-height: 350px;
  }
</style>
